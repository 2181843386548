import { Method } from "axios"

import { Feature } from "src/data/featureAvailability/types/featureAvailabilityTypes"
import { TCountryCode } from "src/data/homes/types/homeTypes"
import { ITranslateFunction } from "src/i18n/i18nTypes"
import { TTranslateFunction } from "src/i18n/useTranslate"

export enum EditType {
  DROPDOWN = "dropdown",
  INPUT_NUMBER = "input-number",
  PHONE_NUMBER = "phone-number",
  INPUT_TEXT = "input-text",
  SLIDER = "slider",
  SLIDER_RANGE = "slider-range",
  STATIC = "static",
  TOGGLE = "toggle",
  SECTION = "section",
  SECTION_TOGGLE = "toggle-section",
  NODE = "node",
}

export type TStoredValue = string | number | boolean
export type TSaveValue = TStoredValue
type TOption = { id: string; name: string }
export type TDefaultValue = TStoredValue | TOption | undefined

interface IEditBase<S, P> {
  type: EditType
  id?: string // should be a unique identifier FIXME: Remove
  title: ((t: ITranslateFunction) => string) | string
  info: string
  defaultValue: TDefaultValue
  translateDisplayValue?: boolean

  /** Return the current stored value for this setting */
  storedValue: (settings: S) => TStoredValue
  /** Format data into a payload to submit to Backend */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
  payload: (value: any, currentSettings: S) => P
  /** Use when you need to pre-process the setting before render */
  populate?: (settings: S, current?: TEditField<S, P>) => TEditField<S, P>
  /** Use when you need to asynchronousely pre-process the setting before render */
  populateAsync?: (settings: S) => Promise<TEditField<S, P>>

  /* Indicate that editing this field necessitates a refetch of homes */
  affectsHomes?: boolean // XXX: Consider implementing a better approach
  /* Additional info, displayed while editing config field */
  guide?: string
  tooltip?: (t: TTranslateFunction) => React.ReactNode
  /** Use this if you need to output, e.g., JSX for info */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
  dynamicInfo?: () => any
  /** Allow changing setting without first clicking edit button */
  inlineEdit?: boolean
  /** Unit, if applicable */
  unit?: string
  /** Some settings are not suitable for bulk editing */
  hideInBulkMode?: boolean
  /** Construct a URL to use when saving setting */
  endpointUrl: (settings: S) => string
  /** Override the default HTTP method (PATCH) when storing a setting change */
  endpointMethod?: Method
  /** Override the default "Edit" text in the EditField */
  editText?: string
  /** If true, editing the field will be disabled */
  disabled?: boolean
  /** Required feature to edit */
  feature?: Feature
  /** Some fields require a clock type; use `populate` to set it dynamically */
  clockType?: "24" | "12" | undefined
  /** Handle specific api errors, by returning an informative string */
  onError?: (errCode: number) => string | null
  /** Dynamic onSave handler, e.g. a react-query async mutation */
  onSave?: (value: TSaveValue) => Promise<void>
  /** Whether to apply text-transform capitalize to the current value, defaults to true */
  capitalize?: boolean
}

export interface IDropdown<S, P> extends IEditBase<S, P> {
  type: EditType.DROPDOWN
  options: IEditFieldDropdownOptions[]
  defaultValue: string
}

export interface ITextInput<T, P> extends IEditBase<T, P> {
  type: EditType.INPUT_TEXT
  placeholder?: string
  label?: string
  defaultValue: string
  maxLength: number | undefined
  warning?: string
  rows?: number
}

export interface INumberInput<T, P> extends IEditBase<T, P> {
  type: EditType.INPUT_NUMBER
  min: number
  max: number
  warningMin?: number
  warningMax?: number
  step: number
  label: string
  defaultValue: number
}

export interface IPhoneInput<T, P> extends IEditBase<T, P> {
  type: EditType.PHONE_NUMBER
  defaultValue: string
  countryCallingCodeEditable?: boolean
  label?: React.ReactNode
  helperText?: React.ReactNode
  defaultCountry?: TCountryCode
  international?: boolean
  required?: boolean
  error?: boolean
}

export interface ISlider<T, P> extends IEditBase<T, P> {
  type: EditType.SLIDER
  min: number
  max: number
  warning: string
  defaultValue: number
}

export interface IRangeSlider<T, P> extends IEditBase<T, P> {
  type: EditType.SLIDER_RANGE
  defaultValue: string
  clockType: "24" | "12" | undefined
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
export interface IStatic<T, P = any> extends IEditBase<T, P> {
  type: EditType.STATIC
  defaultValue: string
}

export interface IToggle<T, P> extends IEditBase<T, P> {
  id: string
  type: EditType.TOGGLE
  trueVal: string | true
  falseVal: string | false
  defaultValue: string | boolean
}

/** All available Config Field types. */
export type TEditField<T, P> =
  | IDropdown<T, P>
  | INumberInput<T, P>
  | ITextInput<T, P>
  | IRangeSlider<T, P>
  | ISlider<T, P>
  | IToggle<T, P>
  | IStatic<T>
  | IPhoneInput<T, P>

export interface IToggleSection<T, P> {
  type: EditType.SECTION_TOGGLE
  toggle: IToggle<T, P>
  fields: TEditField<T, P>[]
  hideInBulkMode?: boolean
}

export interface IEditFieldNode<T = object> {
  key: string
  type: EditType.NODE
  contents: (obj: T) => React.ReactNode | JSX.Element
  hideInBulkMode?: boolean
  filterOutNode?: (obj: T) => boolean
}

export type ISectionContents<T, P> =
  | ISection<T, P>
  | TEditField<T, P>
  | IToggleSection<T, P>
  | IEditFieldNode<T>

export interface ISection<T, P> {
  type: EditType.SECTION
  key: string
  title: ((t: ITranslateFunction) => string) | string
  description?: ((t: ITranslateFunction) => string) | string
  info?: string
  contents: ISectionContents<T, P>[]
}

export interface IEditFieldDropdownOptions {
  name: string
  value: string // it should be id of option in Backend
  key?: string // Unique identifier
  disabled?: boolean
  hidden?: boolean
}
