import { useState } from "react"

import { MTextField } from "src/ui/MTextField/MTextField"

function checkValue(
  displayValue: number | null,
  min: number,
  max: number,
  unit: string,
  warningMin?: number,
  warningMax?: number
): [boolean, string | undefined] {
  let error = false
  let helperText

  if (!displayValue && displayValue !== 0) {
    error = true
    helperText = "Invalid number"
  } else if (displayValue < min) {
    error = true
    helperText = `Minimum value is ${min} ${unit}`
  } else if (displayValue > max) {
    error = true
    helperText = `Maximum value is ${max} ${unit}`
  } else if (displayValue >= min && displayValue < Number(warningMin)) {
    helperText = `Below recommended threshold`
  } else if (displayValue >= max && displayValue < Number(warningMax)) {
    helperText = `Above recommended threshold`
  }
  return [error, helperText]
}

// eslint-disable-next-line import/no-default-export
export default function NumberInput({
  label,
  value,
  min,
  max,
  warningMin,
  warningMax,
  unit,
  setValue,
}: {
  label: string
  value: number | null
  min: number
  max: number
  unit: string
  warningMin?: number
  warningMax?: number
  setValue: (n: number, error: boolean) => void
}) {
  const [displayValue, setDisplayValue] = useState(value)
  const [error, setError] = useState(false)
  const [helperText, setHelperText] = useState<undefined | string>()

  function handleChange(value: string) {
    const parsedValue = Number(value)

    const [error, text] = checkValue(
      parsedValue,
      min,
      max,
      unit,
      warningMin,
      warningMax
    )
    setError(error)
    setHelperText(text)
    setDisplayValue(parsedValue)
    setValue(parsedValue, error)
  }

  return (
    <MTextField
      label={label}
      type="number"
      value={displayValue ? String(displayValue) : ""}
      error={error ? helperText : ""}
      min={min}
      max={max}
      onChange={handleChange}
    />
  )
}
