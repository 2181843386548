import { useState } from "react"

import { PhoneNumberInput } from "src/ui/PhoneNumberInput/PhoneNumberInput"
import { validatePhoneNumber } from "src/ui/PhoneNumberInput/phoneNumberUtil"

export function PhoneNumber({
  phoneNumber,
  onChange,
}: {
  phoneNumber: string
  onChange: (s: string) => void
}) {
  const [userPhone, setUserPhone] = useState<string>(phoneNumber)
  const [phoneError, setPhoneError] = useState<boolean>(false)

  function handlePhoneChange(value?: string) {
    onChange(value || "")
    setUserPhone(value || "")
  }
  return (
    <PhoneNumberInput
      value={userPhone}
      onChange={handlePhoneChange}
      error={phoneError}
      onBlur={() => !validatePhoneNumber(userPhone) && setPhoneError(true)}
      onFocus={() => setPhoneError(false)}
    />
  )
}
