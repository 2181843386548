import { useEffect, useMemo, useState } from "react"
import styled from "styled-components"

import { AxiosResponse } from "axios"

import { UpgradeBlockerDialog } from "src/components/FeatureBlockers/UpgradeBlockerDialog"
import { UpgradeIconButton } from "src/components/FeatureBlockers/UpgradeIconButton"
import {
  EditType,
  TEditField,
  TStoredValue,
} from "src/data/editField/editFieldTypes"
import {
  getDisplayValue,
  getStoredValue,
} from "src/data/editField/logic/editFieldLogic"
import { useFeatureAvailability } from "src/data/featureAvailability/logic/useFeatureAvailability"
import { Feature } from "src/data/featureAvailability/types/featureAvailabilityTypes"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { MButtonLegacy } from "src/ui/Button/MButtonLegacy"
import { TextButton } from "src/ui/Button/TextButton"
import { greyScale, recommendedGray } from "src/ui/colors"
import { MInfo } from "src/ui/Info/MInfo"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

import { ControlType } from "./ControlType"

export type TEditFieldSave<Setting, Payload> = (
  newVal: string | number | boolean,
  configField: TEditField<Setting, Payload>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
) => Promise<AxiosResponse<any>[]> | Promise<any>

export function EditField<Setting, Payload>({
  fieldData,
  submit,
  loading,
  storedSettings,
}: {
  submit: TEditFieldSave<Setting, Payload>
  fieldData: TEditField<Setting, Payload>
  loading: boolean
  storedSettings: Setting[]
}) {
  const [preprocessing, setPreprocessing] = useState(!!fieldData.populateAsync)
  const [processedData, setProcessedData] = useState<TEditField<
    Setting,
    Payload
  > | null>(null)

  useEffect(() => {
    const getCurrent = async () => {
      setPreprocessing(true)
      let f
      if (fieldData.populateAsync) {
        f = await preprocessAsync(fieldData, storedSettings)
      } else {
        f = preprocess(fieldData, storedSettings)
      }
      setProcessedData(f)
      setPreprocessing(false)
    }
    getCurrent()
  }, [fieldData, storedSettings])

  return (
    <EditFieldInner
      fieldData={processedData || fieldData}
      submit={submit}
      loading={loading || preprocessing}
      preprocessing={preprocessing}
      storedSettings={storedSettings}
    />
  )
}

function EditFieldInner<Setting, Payload>({
  fieldData,
  submit,
  loading,
  preprocessing,
  storedSettings,
}: {
  submit: TEditFieldSave<Setting, Payload>
  fieldData: TEditField<Setting, Payload>
  loading: boolean
  preprocessing: boolean
  storedSettings: Setting[]
}) {
  const { _t } = useTranslate()
  const featureAvailability = useFeatureAvailability({
    feature: fieldData.feature || Feature.EDITFIELD_ALLOW,
  })

  const [saveValue, setSaveValue] = useState<TStoredValue>(
    getStoredValue(fieldData, storedSettings, !featureAvailability.available)
  )
  const [backupValue, setBackupValue] = useState<TStoredValue>(saveValue)

  const displayValue = useMemo(() => {
    const stored = getStoredValue(
      fieldData,
      storedSettings,
      !featureAvailability.available
    )
    return fieldData.translateDisplayValue
      ? _t(getDisplayValue(fieldData, stored) as string)
      : getDisplayValue(fieldData, stored)
  }, [featureAvailability.available, fieldData, storedSettings, _t])

  // Update values once preprocessing is finished
  useEffect(() => {
    setSaveValue(
      getStoredValue(fieldData, storedSettings, !featureAvailability.available)
    )
    setBackupValue(
      getStoredValue(fieldData, storedSettings, !featureAvailability.available)
    )
  }, [preprocessing, fieldData, storedSettings, featureAvailability.available])

  const { title, info, guide, tooltip } = fieldData
  const bulkEdit = storedSettings.length > 1
  const saveImmediate = !bulkEdit && fieldData.inlineEdit

  const [showUgradeBlocker, setShowUpgradeBlocker] = useState(false)
  const [editing, setEditing] = useState(false)
  const [saveDisabled, setSaveDisabled] = useState(loading || saveValue === "")

  function onChange({
    newVal,
    saveVal,
    error,
  }: {
    newVal: TStoredValue
    saveVal?: TStoredValue
    error?: boolean
  }) {
    setSaveDisabled(Boolean(error) || !featureAvailability.available)
    setSaveValue(saveVal || newVal)
    if (!error && saveImmediate) {
      onSave(saveVal || newVal)
    }
  }

  async function onSave(value: TStoredValue) {
    if (saveDisabled) {
      return
    }
    setSaveDisabled(true)
    try {
      if (!featureAvailability.available) {
        setShowUpgradeBlocker(true)
        throw Error("Insufficient plan")
      }
      await submit(value, fieldData)
      setBackupValue(value) // Success, overwrite backup value
      setEditing(false)
    } catch (error) {
      setSaveValue(backupValue) // restore old value
    }
    setSaveDisabled(false)
  }

  function onCancel() {
    setEditing(false)
    setSaveValue(backupValue)
  }

  function onEdit() {
    if (!featureAvailability.available) {
      setShowUpgradeBlocker(true)
      return
    }
    setEditing(true)
  }

  function Guide() {
    if (!guide) return null

    return (
      <Description
        style={{ marginTop: "1rem", display: "flex", textAlign: "start" }}
      >
        <i>
          <MText variant="body" color="secondary">
            {_t(guide)}
          </MText>
        </i>
      </Description>
    )
  }

  function BoxEditContents() {
    if (fieldData.type === EditType.STATIC) return <div></div>

    if (saveImmediate) {
      return (
        <ControlType
          conf={fieldData}
          onChange={onChange}
          value={saveValue}
          disabled={saveDisabled}
        />
      )
    }

    return (
      <TextButton
        onClick={onEdit}
        disabled={loading || editing || !!fieldData?.disabled}
      >
        {!!fieldData.editText ? _t(fieldData.editText) : _t(langKeys.edit)}
      </TextButton>
    )
  }

  const _title = typeof title === "function" ? title(_t) : _t(title)

  return (
    <EditFieldBox>
      <Layout>
        <TitleBox>
          <MText variant="subtitle">{_title}</MText>
          {!!tooltip && (
            <span>
              <MInfo content={tooltip(_t)} />
            </span>
          )}
          {!featureAvailability.available && (
            <StyledUpgradeIconButton context="editfield-generic" />
          )}
        </TitleBox>

        <BoxEditContents />
      </Layout>

      <Description>
        <MText variant="body" color="secondary">
          {info && _t(info)}
          {fieldData.dynamicInfo && fieldData.dynamicInfo()}
        </MText>
      </Description>

      {!bulkEdit && !editing && fieldData.type !== "toggle" && (
        <BoxCurrentValue $capitalize={fieldData.capitalize}>
          {preprocessing ? "..." : displayValue}
        </BoxCurrentValue>
      )}

      {editing && (
        <BoxSettingsControl>
          <ControlType
            conf={fieldData}
            onChange={onChange}
            value={saveValue}
            disabled={loading}
          />

          {!saveImmediate && <Guide />}

          <ButtonsWrapper>
            <MButtonLegacy
              style={{ marginRight: `${spacing.S}` }}
              onClick={onCancel}
              disabled={loading}
              variant="text"
            >
              {_t(langKeys.cancel)}
            </MButtonLegacy>
            <MButtonLegacy
              onClick={() => {
                if (saveValue !== null) {
                  onSave(saveValue)
                }
              }}
              disabled={saveDisabled}
              loading={loading}
            >
              {_t(langKeys.save)}
            </MButtonLegacy>
          </ButtonsWrapper>
        </BoxSettingsControl>
      )}
      {saveImmediate && <Guide />}

      {!!fieldData.feature && (
        <UpgradeBlockerDialog
          context="editfield-generic"
          open={showUgradeBlocker}
          onClose={() => setShowUpgradeBlocker(false)}
        />
      )}
    </EditFieldBox>
  )
}

const Description = styled.div`
  margin-right: 60px;
`

// eslint-disable-next-line react-refresh/only-export-components
export const EditFieldBox = styled.div`
  border-bottom: 1px solid ${greyScale[100]};
  padding-bottom: ${spacing.L};
  margin-top: ${spacing.XL};
`

const BoxSettingsControl = styled.div`
  margin-top: ${spacing.M};
  > :first-child {
    width: 100%;
  }
  > :not(:first-child) {
    margin-top: ${spacing.S};
  }
`

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const BoxCurrentValue = styled.div<{ $capitalize?: boolean }>`
  margin-top: ${spacing.L};
  color: ${recommendedGray};
  &:first-letter {
    text-transform: ${(p) => (p.$capitalize === false ? "none" : "capitalize")};
  }
`

const TitleBox = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.XS};
`

const StyledUpgradeIconButton = styled(UpgradeIconButton)`
  margin-left: ${spacing.XS};
`

const Layout = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${spacing.XS};
`

//#region Helper functions
function preprocess<Setting, Payload>(
  editField: TEditField<Setting, Payload>,
  deviceConfigs: Setting[]
): TEditField<Setting, Payload> {
  if (editField.populate && deviceConfigs[0]) {
    return editField.populate(deviceConfigs[0], editField)
  }
  return editField
}

async function preprocessAsync<Setting, Payload>(
  editField: TEditField<Setting, Payload>,
  deviceConfigs: Setting[]
): Promise<TEditField<Setting, Payload>> {
  if (editField.populateAsync && deviceConfigs[0]) {
    return await editField.populateAsync(deviceConfigs[0])
  }
  return editField
}
//#endregion Helper functions
